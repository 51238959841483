/** HOME **/

.api-error {
    top: 50px;
    margin: 40px;
}

.home__select_year {
    width: 110px;
    display: flex;
    justify-content: space-between;
}

.month-calendar-container__nav-buttons {
    display: flex !important;
    position: absolute !important;
    bottom: 0;
    margin-bottom: -20px;
    right: 30px;
}

.month-calendar-container__btn-nav {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    border: 1px solid #dce4ea !important;
    background-color: #fcfdfe !important;
}

.btn-toggle {
    background: #F1F5F9;
    border: 1px solid #FFFFFF;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(2px);
    padding: 10px;
    height: 40px;
    width: 40px;
}

#toggle-false,
#btn-prev {
    border-radius: 14px 0 0 14px;
}

#toggle-true,
#btn-next {
    border-radius: 0 14px 14px 0;
}

.absence-cards-month-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.box-absence-stats {
    .welcome-box {
        margin-bottom: -20px;
    }
    &__date {
        font-size: 11px;
    }

    &__welcome {
        font-size: 21px;
        line-height: 40px;
    }

    &__stats_summary {
        margin-bottom: -25px;
    }
}

.btn-request--main {
    position: absolute;
    min-height: 57px;
    bottom: -28px;
    filter: drop-shadow(0px 6px 24px rgba(52, 220, 162, 0.8));
}

.requests-heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    font-size: 10px;
    line-height: 90%;
    color: #1B2542;
}

.requests-heading:before,
.requests-heading:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgba(140, 157, 174, 0.1);
}

#toggle-calendar-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

#toggle-calendar-view,
#toggle-requests {
    max-height: 100%;
    opacity: 1;
    transition: .4s ease-in;
}

#toggle-calendar-view.hidden,
#toggle-requests.hidden {
    max-height: 0;
    opacity: 0;
    transition: .3s ease-out;
}

#toggle-requests .absence-card,
#toggle-calendar-view.absence-card {
    height: 80px;
    min-height: 80px;
    padding-bottom: 20px;
    overflow: hidden;
    transition: min-height .2s linear;
}

#toggle-requests.large .absence-card {
    min-height: 186px;
    padding-bottom: 0;
    transition: min-height .3s linear;
}

.btn-toggle.active {
    background-color: #FFFFFF !important;
}

.single-stat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    font-size: 11px;
    color: #112C47;
}

.single-stat {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    line-height: 100%;
    color: #112C47;
}

/** ABSENCE CARD **/

.absence-card {
    min-width: 300px;

    &__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        width: 100%;
        height: 40px;
        line-height: 100%;

        .to {
            min-width: 10%;
            justify-content: center;
        }
    }

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 13px;
        width: 100%;
        height: 40px;
    }

    &__header_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: #1B2542;
        line-height: 100%;
        gap: 4px;
    }

    &__request_state {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 20px;
        height: 26px;
    }

    /* large */
    &__category--large {
        font-size: 17px;
        height: 17px;
    }

    &__write_date {
        display: flex;
        flex-direction: row;
        gap: 3px;
        font-size: 10px;
        line-height: 90%;
        color: rgba(27, 37, 66, 0.4);
    }

    /* small */
    &_small {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
    }

    &__category--small {
        font-size: 13px;
        height: 16px;
    }

    &__time_period {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        height: 10px;
        gap: 5px;
    }

    &__bubbles {
        display: flex;
        flex-direction: row;
        gap: 6px;
    }

    /* border style */
    &.time_off {
        border-color: #3FB6EB;
    }

    &.sick_leave {
        border-color: #FCB31A;
    }

    &.special_leave,
    &.uncategorized {
        border-color: #D7B59A;
    }
    
    &.public_holiday{
        border-color: #ef4044;
    }

    &.validate {
        border-style: solid;
    }

    &.confirm {
        border-style: dashed;
    }
}

@media (min-width: 1200px) {

    #toggle-calendar-view,
    #toggle-calendar-view.hidden {
        max-height: 100vh !important;
        opacity: 1 !important;
    }

    .requests-heading {
        padding-top: 30px;
        height: 55px;
    }

    .box-absence-stats {
        max-height: 316px;
        padding: 40px 20px 61px;
        gap: 30px !important;
    }

    .stats-container {
        display: flex;
        justify-content: space-between;
        min-width: 280px;
        gap: 40px;
        border-top: 1px solid #c0c1c39d;
        padding-top: 10px;
    }

    .single-stat {
        width: 60px;
        height: 60px;
    }

    .circle-progress-wrapper .circle-progress .circle-progress-content .circle-progress-content-value {
        font-size: 21px !important;
    }
}

@media (max-width: 1199px) {
    .requests-heading {
        height: 25px;
    }

    .box-absence-stats {
        padding: 30px 20px;
        gap: 20px !important;
    }

    .stats-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 15px;
        height: 99px;
        border-top: 1px dashed  #112C47;
    }
    .stats-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 15px;
        height: 29px;
        
    }
    .stats-container-mobile > .single-stat-container {
        width: 100px;
    }
    .single-stat-container {
        width: 55px;
        height: 69px;
    }

    .single-stat {
        width: 40px;
        height: 40px;
    }

    .circle-progress-wrapper .circle-progress .circle-progress-content .circle-progress-content-value {
        font-size: 16px !important;
    }
}