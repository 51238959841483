.signin-form {
    background: fixed url("../../../public/images/overlay-login.svg") #1B2542 no-repeat 0 100%/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100vh;
}

.btn-ms,
.btn-ms:hover,
.btn-ms:active,
.btn-ms:focus,
.btn-ms:focus-visible {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 251px;
    height: 49px;
    border: 1px solid #2F3752 !important;
    border-radius: 60px !important;
    gap: 10px;
}

.text-ms {
    font-weight: 300;
    font-size: 13px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.8);
}

.email-support {
    position: absolute;
    bottom: 3.5rem;
    font-weight: 300;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.4);
}