.year-calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 25px;

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    padding: 50px;
    gap: 20px;
    width: 890px;
    background: #FFFFFF;
    border-radius: 20px;
  }

  &__btn-back {
    max-height: 40px;
    max-width: 115px;
  }

  &__filter {
    width: 150px;
  }
}

.year-calendar__content .calendar-content__day--inactive {
  visibility: hidden;
}

.year-calendar-month-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.year-calendar__btn-back {
  max-height: 40px;
  max-width: 115px;
}

.form-check-label {
  font-size: 13px;
  line-height: 15px;
  padding: 6px 7px;
  color: #1B2542;
}

.dropdown-toggle {
  background-color: #FFFFFF !important;
  border: 1px solid #E4EBF1;
  box-shadow: none !important;
}

.dropdown-toggle::after {
  content: none !important;
}

@media (max-width: 1199px) {
  .year-calendar__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
    gap: 35px;
    width: 100%;
  }
}