#view-request-form.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .3s, opacity .3s ease-out;
}

.request-form-wrapper {
    max-width: 584px;
}

.request-form {
    &__cross {
        cursor: pointer;
        z-index: 2;
        right: -14px;
        top: -14px;
    }

    &__group_switch_and_bubble {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        padding: 0px 7px;

        width: 100%;
        height: 26px;
    }

    &__bubble_wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    &__group_sick-note {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
    }

    &__box-datepicker {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;

        &--range {
            height: 113px;
        }

        .to {
            z-index: 2;
            position: absolute;
        }

        .date-picker-input {
            border: none;
            height: 40px;
        }
    }

    &__comment {
        height: 134px !important;
        resize: none;
        font-style: italic;
    }

    &__edit-button-box {
        margin-top: -30px !important;
        bottom: -20px;
    }

    &__modification_history {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding: 20px;
        gap: 20px;
        background: #FFFFFF;
        border-radius: 20px;
    }

    &__modification_history_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px;
        width: 100%;
    }

    &.confirm {
        border: 1px solid #F8C272;
        box-shadow: 0px 0px 0px 4px rgba(248, 194, 114, 0.2);
    }

    &.validate {
        border: 1px solid #34DCA2;
        box-shadow: 0px 0px 0px 4px rgba(52, 220, 162, 0.2);
    }

    &.refuse {
        border: 1px solid #F87272;
        box-shadow: 0px 0px 0px 4px rgba(248, 114, 114, 0.05);
    }
}

/** Custom CSS Datepicker Plugin **/
.react-google-flight-datepicker {
    .date-picker {
        padding: 0;
    }

    .date-picker.disabled {
        opacity: 1;
    }

    .date-picker.disabled .date {
        cursor: default;
    }

    .dialog-header .date-picker-date-group {
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .selected-date {
        font-size: 13px;
        letter-spacing: 0;
    }

    .date {
        max-height: 40px;
    }

    .date.is-focus {
        background-color: #FFFFFF;
    }

    .date.is-focus::after,
    .selected-date.is-focus::after {
        border: none;
    }

    .date.is-focus::before,
    .dialog-header .date-picker-date-group::before {
        content: none;
    }

    .day.selected::after,
    .day.selected.hovered::after,
    .day.hovered:hover::after {
        background-color: #24A1D2;
    }

    .submit-button,
    .day.highlight::before {
        background: #34DCA2 !important;
        border-radius: 22px !important;
    }

    .day.highlight:not(:hover) {
        color: #FFFFFF;
    }

    .day:hover::after {
        border: 2px solid #24A1D2;
    }

    .day.hovered {
        background: rgba(36, 161, 210, 0.15);
    }

    .day.selected.hovered {
        background: linear-gradient(90deg, #fff 50%, rgba(36, 161, 210, 0.15) 50%);
    }

    .day.selected.hovered.end {
        background: linear-gradient(90deg, rgba(36, 161, 210, 0.15) 50%, #fff 50%);
    }
    // time off
    div[time_off]:not(:hover) {
        color: #FFFFFF;
    }
    div[time_off]:after {
        background: #3FB6EB !important;
    }

    div[time_off].hovered {
        background: transparent !important;
    }

    div[time_off].hovered.end {
        background: transparent !important;
    }
    // Sick leave
    div[sick_leave]:not(:hover) {
        color: #FFFFFF;
    }
    div[sick_leave]:after {
        background: #FCB31A !important;
    }
    div[sick_leave].hovered {
        background: #fae9c3 !important;
    }
    div[sick_leave].hovered.end {
        background: linear-gradient(90deg, rgba(63, 183, 235, 0.15) 50%, #fff 50%) !important;
    }
    // Special Leave
    div[special_leave]:not(:hover) {
        color: #FFFFFF;
    }
    div[special_leave]:after {
        background: #D7B59A !important;
    }
    div[special_leave].hovered {
        background: #ffe9c3 !important;
    }
    div[special_leave].hovered.end {
        background: linear-gradient(90deg, rgba(63, 183, 235, 0.15) 50%, #fff 50%) !important;
    }
    // No main category set
    div[uncategorized]:not(:hover) {
        color: #FFFFFF;
    }
    div[uncategorized]:after {
        background: #f4ceaf !important;
    }
    div[uncategorized].hovered {
        background: #ffe9c3 !important;
    }
    div[uncategorized].hovered.end {
        background: linear-gradient(90deg, rgba(156, 204, 224, 0.15) 50%, #fff 50%) !important;
    }
    // public holiday
    div[public_holiday]:not(:hover) {
        color: #FFFFFF;
    }
    div[public_holiday]:after {
        background: rgba(239, 64, 68, 0.75) !important;
    }
    div[public_holiday].hovered {
        background: rgba(239, 64, 68, 0.15) !important;
    }

    div[public_holiday].hovered.end {
        background: transparent !important;
    }
}

.range-datepicker .date-picker-date-group {
    flex-direction: column;
}

.range-datepicker .date-picker-input {
    border: none;
    height: 113px;
}

.custom-datepicker .icon-calendar.mobile,
.icon-calendar,
.change-date-button {
    display: none;
}

#end-date-input-button {
    margin: 0;
}

.react-select,
.react-google-flight-datepicker.dialog-date-picker.open {
    z-index: 3;
}

::-webkit-input-placeholder,
.input-placeholder {
    font-style: italic !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: rgba(27, 37, 66, 0.5) !important;
}

#react-select-2-placeholder,
.date-placeholder,
.css-olqui2-singleValue {
    color: #1B2542 !important;
}

.css-b62m3t-container {
    padding: 0 !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-16xfy0z-control,
.css-13cymwt-control:hover,
.css-t3ipsp-control:hover {
    border: none !important;
    box-shadow: none !important;
    border-radius: 14px !important;
    background-color: transparent !important;
}

select:disabled,
.css-3iigni-container,
.form-select:disabled,
.input:disabled,
.date-picker.disabled .date {
    color: #1B2542 !important;
    background-color: rgba(228, 235, 241, 0.1) !important;
    border: 1px solid #E4EBF1;
}

.css-1wy0on6 {
    display: none !important;
}

.form-switch {
    height: 26px;
    gap: 10px;
    margin: 0px 2px;

    .form-check-input {
        width: 38px !important;
        height: 20px;
        background-color: rgba(140, 157, 174, 0.15);
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
        border: none;
    }

    .form-check-input:checked {
        background-color: #24A1D2;
        border-color: #24A1D2;
    }
}

.refusal-comment {
    margin-top: 1em;
    height: 86px !important;
    resize: none;
    font-style: italic;
}

@media (min-width: 1200px) {
    .request-form-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: absolute;
        top: 130px;
        width: 520px;
    }

    .row-holiday-stats {
        width: 100%;
        height: 42px;
        margin-top: 50px;
        border-radius: 14px;
        background-color: rgba(255, 255, 255, 0.5);
    }
}

@media (max-width: 1199px) {
    .request-form-component {
        position: absolute;
        height: 100%;
        overflow-y: auto;
    }

    .request-form-wrapper {
        width: 100%;
    }

    .request-form {
        position: relative;
        margin-bottom: 20px;
    }

    #view-request-form {
        z-index: 3;
    }
}

// New date picker classes:


.date-picker-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out items */
  }

.new-date-picker {
    width: 100%; /* Full width to match the parent container */
    margin-bottom: 8px; /* Space at the bottom of each date picker */
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #E4EBF1 !important;
    border-radius: 14px !important;
    font-size: 13px;
    color: #1B2542 !important;
    height: 40px;
    padding: 0 13px;
  }

.date-range-separator {
    margin-bottom: 8px; /* Space below the "to" text */
    font-style: italic;
    font-size: 11px;
    line-height: 0px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    color: rgba(39, 51, 64, 0.7);
/* Add any additional styling for this divider text */
}

// Marks on calendar
.react-datepicker__day--highlighted-public-holiday:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background-color: rgba(239, 64, 68, 0.75);
}

.react-datepicker__day--highlighted-time-off:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background: #3FB6EB;
}

.react-datepicker__day--highlighted-sick:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background: #FCB31A;
}
.react-datepicker__day--highlighted-special:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background: #D7B59A;
}
.react-datepicker__day--highlighted-uncategorized:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background: #f4ceaf;
}
.react-datepicker__day--today:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selected) {
    border-radius: 50%;
    background: #34DCA2;
}
.react-datepicker__day--keyboard-selected {
    background-color: inherit;
}
