html,
body {
    position: relative;
    height: 100%;
    scroll-behavior: auto;
    overscroll-behavior: none;
}

html {
    overflow: hidden;
}

body {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    overflow: auto;
}

a,
.btn-link {
    text-decoration: none;
}

b {
    font-weight: 600;
}

*:focus-visible {
    outline-color: #24A1D2;
}

img {
    display: none;
}

img[src] {
    display: block;
    max-width: 100%;
}

.app {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
}

.page-container {
    margin-bottom: 30px;

    &__divider {
        width: 100%;
        height: 1px;
        margin-top: 5px;
        background: rgba(140, 157, 174, 0.1);
    }
}

.default-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: #FFFFFF;
    color: #1B2542;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(2px);
    border-radius: 20px;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.topbar {
    width: 100%;
    height: 40px;
}

.footer-button-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 40px;
    width: 100%;
    height: 110px;
    position: fixed;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    background: linear-gradient(180deg, rgba(228, 235, 241, 0) 0%, #E4EBF1 100%);
}

#view-requests,
#view-request-form,
#view-main-menu,
.global-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
}

#view-request-form,
#view-main-menu,
.global-message {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(27, 37, 66, 0.6);
}

#view-request-form,
#view-main-menu {
    z-index: 5;
    overflow-y: auto;
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease-in;
}

.input {
    padding: 12.5px 13px !important;
}

select,
.react-select,
.form-select,
.input,
.react-google-flight-datepicker .date {
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-color: #E4EBF1 !important;
    border-radius: 14px !important;
    font-size: 13px;
    color: #1B2542 !important;
    height: 40px;
    width: 100%;
    padding: 0 13px;
}

.form-select,
.custom-datepicker .date,
.css-13cymwt-control,
.css-t3ipsp-control,
.css-16xfy0z-control {
    -webkit-appearance: none;
    appearance: none;
    background: url("../../public/images/icon-arrow-select.svg") right 11px center/18px no-repeat #FFFFFF;
}

.btn-request,
.btn-request:hover,
.btn-request:focus,
.btn-request:focus-visible,
.btn-request:active,
.btn-request:disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 150px;
    height: 52px;
    background-color: #34DCA2;
    border-radius: 56px;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
}

.btn-request--cancel {
    background: #FF6767 !important;
}

.btn-request--mobile {
    height: 57px !important;
}

.btn-secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 150px;
    height: 57px;
    color: #1B2542;
    background: #F1F5F9;
    border: 1px solid #FFFFFF;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(2px);
    border-radius: 54px;
    font-size: 13px;
    line-height: 100%;
}

.alert {
    max-width: 584px;
}

.global-message {
    z-index: 3;
}

.row-holiday-stats__single {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 5px;
    height: 42px;
    font-size: 11px;
    line-height: 100%;
    text-transform: capitalize;
    color: #112C47;
}

.row-holiday-stats__single-bubble_state {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 8px;
    height: 22px;
    background: rgba(140, 157, 174, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    font-weight: 600;
    font-size: 11px;
    line-height: 90%;
}

/** HOME & REQUEST FORM shared **/

.request-form,
.absence-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    gap: 20px;
    isolation: isolate;
    background: #FFFFFF !important;
    border-radius: 20px;
    border: 2px;
}

.bubble_duration {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 8px 10px;
    gap: 10px;
    width: 27px;
    height: 26px;
    background: rgba(228, 235, 241, 0.05);
    border: 1px solid #E4EBF1;
    border-radius: 20px;
    font-weight: 600;
    font-size: 11px;
    line-height: 90%;
}

.bubble_state {
    padding: 8px 10px;
    height: 26px;
    border-radius: 99px;
    font-weight: 600;
    font-size: 11px;
    line-height: 90%;

    &--red {
        color: #F87272;
        border: 1px solid #F87272;
        background: rgba(248, 114, 114, 0.05);
    }

    &--orange {
        color: #F8C272;
        border: 1px solid #F8C272;
        background: rgba(248, 194, 114, 0.05);
    }

    &--green {
        color: #34DCA2;
        border: 1px solid #34DCA2;
        background: rgba(52, 220, 162, 0.05);
    }

    &--small {
        font-size: 10px !important;
    }
}

.to,
.of,
.custom-datepicker .date-picker-date-group:before {
    font-style: italic;
    font-size: 11px;
    line-height: 13px;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgba(39, 51, 64, 0.7);
}

.edit-button-box {
    height: 45px;
    width: 90px;
    margin-top: -45px;
    right: -20px;

    &__icon {
        padding-right: 6px;
    }

    &__bg {
        height: 100%;
        width: 96px;
    }
}

.btn-edit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    position: absolute;
    width: 78px;
    height: 26px;
    padding-right: 7.25px;
    right: 0;
    bottom: 7.63px;
    opacity: 0.7;
    font-size: 11px;
    line-height: 100%;
}

.select-year__button--active {
    border: none;
    background: none;
    padding: 0;
    height: 40px;
}

.select-year__button--inactive {
    line-height: 40px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 70px;

    .greeting {
        &__hello {
            font-size: 12px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            color: #24A1D2;
        }

        &__given-name {
            margin: 0;
            color: #1B2542;
        }
    }

    &__avatar {
        margin: 12px;
    }
}

.navbar {
    padding: 0px;
}

.main-menu {
    &__list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 10px;
        width: 270px;
        height: 40px;
        cursor: pointer;
    }

    &__list-item-text {
        font-size: 13px;
        line-height: 100%;
        color: #1B2542;
        padding: 0px 10px;
        gap: 10px;
    }

    &__list-item-text--red {
        color: #FF6767;
    }

    &__divider {
        width: 250px;
        height: 1px;
        background: #EBEFF2;
    }
}

@media (min-width: 1200px) {
    .app {
        background: fixed url("../../public/images/overlay-desktop.svg") #E4EBF1 no-repeat top right/cover;
    }

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1200px;
    }

    .main-container {
        margin-top: 60px;
    }

    .header {
        width: 1200px;
        background: #FFFFFF;
        box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.03);
        backdrop-filter: blur(2px);

        &__avatar {
            margin: 12px;
        }
    }

    .main-menu {
        padding: 10px 5px;
        width: 280px;
    }

    .main-menu-component {
        z-index: 4;
        padding-right: 30px;
    }

    .feed {
        gap: 20px;
    }
}

@media (max-width: 1199px) {

    .app {
        background: scroll url("../../public/images/overlay-mobile.svg") #E4EBF1 no-repeat right top/100%;
    }

    .bg-feed,
    #view-main-menu {
        background: scroll url("../../public/images/overlay-mobile.svg") #E4EBF1 no-repeat right -105px/100%;
    }

    .header {
        z-index: 4;
        height: 100%;
        min-height: 105px;
        max-width: 612px;
    }

    .navbar {
        padding: 30px 40px 0;
    }

    #view-main-menu,
    .global-message {
        top: 105px;
    }

    .alert {
        font-size: 14px;
    }

    .main-menu {
        padding: 10px 5px;
        max-width: 310px;
        margin-bottom: 150px;
    }

    #icon-menu,
    #greeting {
        visibility: visible;
        opacity: 1;
        height: 70px;
        transition: opacity .3s ease-in, height .3s ease-in;
    }

    #icon-menu.invisible,
    #greeting.invisible {
        visibility: hidden;
        opacity: 0;
        height: 0;
        transition: visibility 0s .3s, opacity .3s ease-out, height .3s ease-out;
    }

    .view {
        padding: 0 40px 90px 40px;
        width: 100%;
    }

    .feed,
    .topbar {
        max-width: 570px;
    }
}