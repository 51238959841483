.faq {
    padding: 0 20px 100px 20px;
}

.accordion-button:not(.collapsed) {
    color: #1B2542;
    background-color: initial;
    box-shadow: initial;
}

.accordion-button:focus {
    box-shadow: initial;
}

.faq__video-container {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
}

#video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}