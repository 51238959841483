.month-calendar-container {
  padding: 30px 30px 40px;
  border: 1px solid #FFFFFF;
  opacity: 0.9;
  margin-bottom: 20px;
  height: 450px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.month-calendar-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.select-year,
.year-calendar-month-wrapper__heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  height: 40px;
  gap: 5px;
  margin-left: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 24.3922px;
  line-height: 29px;
}

.selectedMonth {
  width: 115px !important;
}

.year-calendar-month-wrapper {
  width: 240px;
}

.calendar__weekdays-row {
  width: 100%;
  font-size: 13px;
  display: flex;
}

.calendar__weekday-short {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin: 0;
  flex-grow: 1;
}

.today {
  color: white !important;
  background: #34DCA2;
  border-radius: 99px;
}

.calendar-content {
  &__week-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 13px;
  }

  &__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 34px;
  }

  &__day {
    width: 26px;
    height: 26px;
    margin: 4px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    letter-spacing: -0.5px;
    color: #273340;
  }

  &__absence:after {
    content: "";
    height: 34px;
    width: 34px;
    margin: auto;
    position: absolute;
    left: -4px;
    top: 0;
    bottom: 0;
    border-width: 2px 0;
    cursor: pointer;
  }

  &__absence.public_holiday:after {
    cursor: default !important;
  }

  &__absence.validate:after {
    border-style: solid;
  }

  &__absence.confirm:after,
  &__absence.validate1:after {
    border-style: dashed;
  }

  &__absence.single:after {
    width: 34px;
    border-radius: 100%;
    border-width: 2px;
  }

  &__absence.start:after,
  &__absence:not(.start):not(.single).row-start:after {
    left: -6px;
    width: 36px;
    border-left-width: 2px;
  }

  &__absence:not(.start):not(.single).row-start:after {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &__absence.start:after {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  &__absence.end:after,
  &__absence:not(.end):not(.single).row-end:after {
    width: 36px;
    border-right-width: 2px;
  }

  &__absence:not(.end):not(.single).row-end:after {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__absence.end:after {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  &__absence.time_off:after {
    background: rgb(63, 182, 235, 0.1);
    border-color: #3FB6EB;
  }

  &__absence.sick_leave:after {
    background: rgb(252, 179, 26, 0.1);
    border-color: #FCB31A;
  }

  &__absence.special_leave:after,
  &__absence.uncategorized:after {
    background: rgb(215, 181, 154, 0.1);
    border-color: #D7B59A;
  }

  &__public_holiday {
    color: white;
    background: #d90d00;
    border-radius: 99px;
  }

  &__day--inactive {
    color: rgba(39, 51, 64, 0.4);
  }

  &__overlay {
    position: absolute;
    top: 36px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.mirrored {
  transform: scaleX(-1);
}

.disabled {
  opacity: 0.25;
}

@media (min-width: 1200px) {
  .month-calendar-container {
    width: 300px;
  }
}